import React, { useContext, useEffect, useState } from 'react';
import { CodeContext } from '../context/CodeContext';
import { conf } from '../config';
import './DayModal.css';

function DayModal({ open, handleClose, dayData }) {

    const [selectedVote, setSelectedVote] = useState(dayData.vote || '');

    const { code, setCode } = useContext(CodeContext);

    useEffect(() => {
        setSelectedVote(dayData.vote || '');
    }, [dayData]);

    if (!open) return null;

    const handleVoteChange = (e) => {
        const vote = e.target.value;
        setSelectedVote(vote);

        fetch(`${conf.node_env === "production" ? "" : "http://localhost:5000"}/api/day/${dayData.day}/vote`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ vote })
        })
            .catch(error => {
                console.error('Error saving vote:', error);
            });
    };

    return (
        <div className="modal-overlay" onClick={handleClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <img
                    src={`${conf.node_env === "production" ? "" : "http://localhost:5000"}${dayData.image_path}`}
                    alt="Day"
                    className="modal-image"
                />
                <div className="modal-text">
                    <h2>{dayData.text}</h2>
                    {dayData.reward ?
                        <>
                            <p>Félicitations ! Tu as gagné un bon pour le <strong>Jour {dayData.day}</strong> ! Récupère-le pour demander ton prix !</p>
                            <div className="modal-bon">
                                <div className="modal-bon-text">
                                    <h3><strong>Bon du Jour {dayData.day}</strong></h3>
                                    <h4>{dayData.reward.item}</h4>
                                </div>
                                <p>Code : {code}</p>

                            </div>
                        </> :
                        <p>Pas de récompense pour le <strong>Jour {dayData.day}</strong>...</p>
                    }
                </div>
                <div className="vote-section">
                    <p>A quel point tu as <strong>chaud</strong> ? Note cette image :</p>
                    <form>
                        <div className="vote-option">
                            <input
                                type="radio"
                                id="vote-cold"
                                name="vote"
                                value="cold"
                                checked={selectedVote === 'cold'}
                                onChange={handleVoteChange}
                            />
                            <label htmlFor="vote-cold">🥶</label>
                        </div>
                        <div className="vote-option">
                            <input
                                type="radio"
                                id="vote-mid-cold"
                                name="vote"
                                value="mid-cold"
                                checked={selectedVote === 'mid-cold'}
                                onChange={handleVoteChange}
                            />
                            <label htmlFor="vote-mid-cold">😬</label>
                        </div>
                        <div className="vote-option">
                            <input
                                type="radio"
                                id="vote-neutral"
                                name="vote"
                                value="neutral"
                                checked={selectedVote === 'neutral'}
                                onChange={handleVoteChange}
                            />
                            <label htmlFor="vote-neutral">😐</label>
                        </div>
                        <div className="vote-option">
                            <input
                                type="radio"
                                id="vote-mid-hot"
                                name="vote"
                                value="mid-hot"
                                checked={selectedVote === 'mid-hot'}
                                onChange={handleVoteChange}
                            />
                            <label htmlFor="vote-mid-hot">😊</label>
                        </div>
                        <div className="vote-option">
                            <input
                                type="radio"
                                id="vote-hot"
                                name="vote"
                                value="hot"
                                checked={selectedVote === 'hot'}
                                onChange={handleVoteChange}
                            />
                            <label htmlFor="vote-hot">🥵</label>
                        </div>
                    </form>
                </div>
                <button onClick={handleClose} className="close-button">Fermer</button>
            </div>
        </div>
    );
}

export default DayModal;