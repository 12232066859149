import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DayModal from './DayModal';
import { CodeContext } from '../context/CodeContext';
import { conf } from '../config';
import './Calendar.css';

function Calendar() {
    const [selectedDay, setSelectedDay] = useState(null);
    const [dayData, setDayData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [daysImages, setDaysImages] = useState([]);

    const { code, setCode } = useContext(CodeContext);

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const currentDate = conf.node_env === "production" ? new Date(`${currentYear}-${currentMonth}-${currentDay}`) : new Date(`${currentYear}-${12}-${25}`)


    useEffect(() => {
        const fetchDaysData = async () => {
            try {
                const response = await axios.post(`${conf.node_env === "production" ? "" : "http://localhost:5000"}/api/days-data`, { code }, { withCredentials: true });
                setDaysImages(response.data);
            } catch (err) {
                console.error("Failed to fetch days data", err);
            }
        };
        fetchDaysData();
    }, []);

    const handleDayClick = async (day) => {
        try {
            const response = await axios.post(`${conf.node_env === "production" ? "" : "http://localhost:5000"}/api/day/${day}`, { code }, { withCredentials: true });
            setDayData(response.data);
            setSelectedDay(day);
            setModalOpen(true);
        } catch (err) {
            alert(err.response.data.message);
        }
    };

    const handleLogout = async () => {
        await axios.post(`${conf.node_env === "production" ? "" : "http://localhost:5000"}/api/logout`, {}, { withCredentials: true });
        window.location.reload();
    };

    return (
        <div className="calendar-container">
            <div className="calendar-header">
                <h2>Calendrier Hot'anwe</h2>
                <button className="logout-button" onClick={handleLogout}>
                    Trop chaud ? 🥵
                </button>
            </div>
            <div className="calendar-grid">
                {[...Array(24)].map((_, index) => {
                    const day = index + 1;
                    const dayImage = daysImages.find(item => item.day === day)?.image_path;
                    const date = new Date(`${currentYear}-12-${day}`)
                    const isAvailable = date <= currentDate;
                    return (
                        <div
                            key={index}
                            className={`calendar-cell ${isAvailable && (dayImage !== undefined) ? 'available-img' : (isAvailable && (dayImage === undefined) ? 'available-color' : 'unavailable')}`}
                            onClick={() => isAvailable && handleDayClick(day)}
                            style={{
                                backgroundImage: isAvailable ? `url(${conf.node_env === "production" ? "" : "http://localhost:5000"}${dayImage})` : '',
                            }}
                        >
                            <span>{day}</span>
                        </div>
                    );
                })}
            </div>
            <p>Un projet <strong>délirant</strong> de la tribu codé avec ♥ par <strong>Toff</strong>{" (et un peu "}<strong>ChatGPT</strong>{")"} !<br />Sur une idée originale de <strong>PolyM0rphine</strong> !</p>
            {dayData && (
                <DayModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    dayData={dayData}
                />
            )}
        </div>
    );
}

export default Calendar;