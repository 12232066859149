import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Calendar from './components/Calendar';
import Login from './components/Login';
import axios from 'axios';

axios.defaults.withCredentials = true;

function App() {
    const [authenticated, setAuthenticated] = useState(false);

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={authenticated ? <Calendar /> : <Navigate to="/login" />}
                />
                <Route
                    path="/login"
                    element={<Login setAuthenticated={setAuthenticated} />}
                />
            </Routes>
        </Router>
    );
}

export default App;