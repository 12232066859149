import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CodeContext } from '../context/CodeContext';
import { conf } from '../config';
import './Login.css';

function Login({ setAuthenticated }) {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { code, setCode } = useContext(CodeContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${conf.node_env === "production" ? "" : "http://localhost:5000"}/api/login`, { code });
            setAuthenticated(true);
            navigate('/');
        } catch (err) {
            setError('Invalid code');
        }
    };

    return (
        <div className="login-container">
            <h2>Bienvenue sur le calendrier <strong>Hot</strong>'anwe !</h2>
            <p>Veuillez entrer votre code pour accéder au contenu <strong>le plus chaud</strong> de toute la région. 🥵</p>
            <form onSubmit={handleSubmit} className="login-form">
                <input
                    type="text"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                    className="login-input"
                />
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="login-button">Valider mon code personnel</button>
            </form>
        </div>
    );
}

export default Login;